<template>
  <section>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>

    <div class="max-w-6xl mx-auto mt-3 lg:mt-10 md:mt-5" v-if="isLoaded">
      <!-- Group Trip -->
      <div class="w-full">
        <div class="font-bold text-22px text-oCharcoal">
          <!-- <i class="fas fa-cog text-22px text-oPurple"></i> -->
          <span>Group Trip</span>
        </div>
        <div
          :key="`groupTripSettings_${counter}`"
          class=" mt-3 pt-5 border-t-2"
        >
          <template v-for="(item, itemIndex) in groupTripSettings">
            <SmartAppInput
              :key="itemIndex"
              :rowId="`groupTrip_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              v-model="groupTrip[`${item.variable}`]"
              @save="onSave('organization', `${item.variable}`, $event)"
            />
          </template>
        </div>
      </div>
      <!-- Start Trip -->
      <div class="w-full">
        <div class="font-bold text-22px text-oCharcoal">
          <!-- <i class="fas fa-cog text-22px text-oPurple"></i> -->
          <span>Start Trip</span>
        </div>
        <div
          :key="`startTripSettings_${counter}`"
          class=" mt-3 pt-5 border-t-2"
        >
          <template v-for="(item, itemIndex) in startTripSettings">
            <SmartAppInput
              :key="itemIndex"
              :rowId="`startTrip_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :options="item.options"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              v-model="startTrip[`${item.variable}`]"
              @save="onSave('vehicle', `${item.variable}`, $event)"
            />
          </template>
        </div>
      </div>

      <!-- Pause Trip -->
      <div class="w-full">
        <div class="font-bold text-22px text-oCharcoal">
          <!-- <i class="fas fa-cog text-22px text-oPurple"></i> -->
          <span>Pause Trip</span>
        </div>
        <div
          :key="`pauseTripSettings_${counter}`"
          class=" mt-3 pt-5 border-t-2"
        >
          <template v-for="(item, itemIndex) in pauseTripSettings">
            <SmartAppInput
              :key="itemIndex"
              :rowId="`pauseTrip_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :options="item.options"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              v-model="pauseTrip[`${item.variable}`]"
              @save="onSave('vehicle', `${item.variable}`, $event)"
            />
          </template>
        </div>
      </div>

      <!-- End Trip -->
      <div class="w-full">
        <div class="font-bold text-22px text-oCharcoal">
          <!-- <i class="fas fa-cog text-22px text-oPurple"></i> -->
          <span>End Trip</span>
        </div>
        <div :key="`endTripSettings_${counter}`" class=" mt-3 pt-5 border-t-2">
          <template v-for="(item, itemIndex) in getEndTripSettings">
            <SmartAppInput
              :key="itemIndex"
              :rowId="`endTrip_${itemIndex}`"
              :type="item.type"
              :label="item.label"
              :options="item.options"
              :placeholder="item.placeholder"
              :description="item.description"
              :isEdit="item.isEdit"
              v-model="endTrip[`${item.variable}`]"
              @save="onSave('vehicle', `${item.variable}`, $event)"
            />
          </template>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import SmartAppInput from '@/components/form/SmartAppInput.vue'
import { useEndpoints } from '@/composables'
import { VehicleSettingsConfig } from '@/config/SettingsConfig'
import { EventBus } from '@/utils'
export default {
  name: 'TripSettings',
  components: {
    SmartAppInput,
  },
  data() {
    return {
      isLoaded: false,
      counter: 0,
      currentSettingsGroup: null,
      groupTrip: {
        number_of_guests_allowed_per_trip: 0,
      },
      startTrip: {
        min_power_level_for_trip: 0,
        allow_unlock_outside_service_area: false,
        allow_unlock_inside_restricted_area: false,
      },
      pauseTrip: {
        pause_ride_hardware_action: '',
      },
      endTrip: {
        complete_trip_after_failed_lock_attempts: '',
        required_lock_attempts_to_complete_trip: '',
        end_ride_location_verification: '',
      },
      groupTripSettings: [
        {
          type: 'number',
          label: 'Number of guests allowed per trip',
          placeholder: 'e.g 3',
          description: `Please provide Max Number of guests each Rider can allow per trip.`,
          variable: 'number_of_guests_allowed_per_trip',
          isEdit: false,
        },
      ],
      startTripSettings: [
        {
          type: 'richselect',
          label: 'Min. battery power required for trip',
          description:
            'Select Minimum battery power required to take the trip.',
          options: [
            { value: 1, text: '1%' },
            { value: 2, text: '2%' },
            { value: 3, text: '3%' },
            { value: 4, text: '4%' },
            { value: 5, text: '5%' },
            { value: 6, text: '6%' },
            { value: 7, text: '7%' },
            { value: 8, text: '8%' },
            { value: 9, text: '9%' },
            { value: 10, text: '10%' },
            { value: 11, text: '11%' },
            { value: 12, text: '12%' },
            { value: 13, text: '13%' },
            { value: 14, text: '14%' },
            { value: 15, text: '15%' },
            { value: 16, text: '16%' },
            { value: 17, text: '17%' },
            { value: 18, text: '18%' },
            { value: 19, text: '19%' },
            { value: 20, text: '20%' },
          ],
          variable: 'min_power_level_for_trip',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: 'Allow Ride to take trip out of geofence',
          description: `Select 'YES' if you want rider to take trip outside of geofence. Select 'NO' if you don't want to allow Rider to take trip outside of geofence.`,
          options: [
            { value: true, text: 'Yes' },
            { value: false, text: 'No' },
          ],
          variable: 'allow_unlock_outside_service_area',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: 'Allow Ride to take trip inside restricted area',
          description: `Select 'YES' if you want rider to take trip inside restricted area . Select 'NO' if you don't want to allow Rider to take trip inside of restricted area.`,
          options: [
            { value: true, text: 'Yes' },
            { value: false, text: 'No' },
          ],
          variable: 'allow_unlock_inside_restricted_area',
          isEdit: false,
        },
      ],
      pauseTripSettings: [
        {
          type: 'richselect',
          label: 'When Rider Pause the Ride vehicle should',
          description: `While Rider will pause the Ride from the App, system will trigger hardware(Throttle or Lock) actions based on your configuration.`,
          options: [
            { value: 'THROTTLE_OFF', text: 'Turn Throttle Off' },
            { value: 'LOCK', text: 'Lock the vehicle' },
          ],
          variable: 'pause_ride_hardware_action',
          isEdit: false,
        },
      ],
      endTripSettings: [
        {
          type: 'richselect',
          label: 'Complete trip after failed lock attempts',
          description: `Select 'YES' if you want System Complete trip first and later Lock the Vehicle. It will help rider complete trip quickly if IOT doesn't respond properly . Select 'No' if you want Rider to Lock Vehicle First then Complete the Ride. It will increase vehicle locking probability but will delay rider trip time if IOT doesn't respond properly.`,
          options: [
            { value: true, text: 'Yes' },
            { value: false, text: 'No' },
          ],
          variable: 'complete_trip_after_failed_lock_attempts',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: 'Required lock attempts to complete trip',
          description: `Select Max Number of Lock Attempt Rider can try if IOT doesn't respond properly. Recommend Lock Attempt Value should be in between ( 1-5).`,
          options: [
            { value: 1, text: 1 },
            { value: 2, text: 2 },
            { value: 3, text: 3 },
            { value: 4, text: 4 },
            { value: 5, text: 5 },
          ],
          variable: 'required_lock_attempts_to_complete_trip',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: 'End Ride Location Verification',
          description:
            'Select when the rider end the ride which location data will be used as a verification source.',
          options: [
            { value: 'U', text: 'Rider' },
            { value: 'V', text: 'Vehicle' },
            { value: 'A', text: 'Rider & Vehicle' },
          ],
          variable: 'end_ride_location_verification',
          isEdit: false,
        },
      ],
    }
  },
  async created() {
    const orgReq = this.$http.get(useEndpoints.organization.details(this.orgId))
    const vehicleReq = this.$http.get(
      VehicleSettingsConfig.api.index(this.orgId)
    )
    const requests = [orgReq, vehicleReq]
    await this.$http.all(requests).then(
      this.$http.spread((...responses) => {
        this.isLoaded = true
        const [orgRes, vehicleRes] = responses
        const orgResData = orgRes.data
        const vehicleResData = vehicleRes.data

        //group trip
        this.groupTrip.number_of_guests_allowed_per_trip =
          orgResData.number_of_guests_allowed_per_trip

        //start trip
        this.startTrip.min_power_level_for_trip =
          vehicleResData.min_power_level_for_trip

        this.startTrip.allow_unlock_outside_service_area =
          vehicleResData.allow_unlock_outside_service_area

        this.startTrip.allow_unlock_inside_restricted_area =
          vehicleResData.allow_unlock_inside_restricted_area

        //pause trip
        this.pauseTrip.pause_ride_hardware_action =
          vehicleResData.pause_ride_hardware_action

        //end trip
        this.endTrip.complete_trip_after_failed_lock_attempts =
          vehicleResData.complete_trip_after_failed_lock_attempts

        this.endTrip.required_lock_attempts_to_complete_trip =
          vehicleResData.required_lock_attempts_to_complete_trip

        this.endTrip.end_ride_location_verification =
          vehicleResData.end_ride_location_verification
      })
    )
  },
  mounted() {
    EventBus.$on('open-edit', (id) => {
      const idPrefix = id.split('_')[0]
      if (idPrefix === 'groupTrip') {
        this.groupTripSettings = this.groupTripSettings.map(
          (item, itemIndex) => {
            if (id === `groupTrip_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'groupTrip'
      }
      if (idPrefix === 'startTrip') {
        this.startTripSettings = this.startTripSettings.map(
          (item, itemIndex) => {
            if (id === `startTrip_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'startTrip'
      }
      if (idPrefix === 'pauseTrip') {
        this.pauseTripSettings = this.pauseTripSettings.map(
          (item, itemIndex) => {
            if (id === `pauseTrip_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'pauseTrip'
      }
      if (idPrefix === 'endTrip') {
        this.endTripSettings = this.endTripSettings.map((item, itemIndex) => {
          if (id === `endTrip_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'endTrip'
      }
    })
  },
  watch: {
    currentSettingsGroup: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val === 'groupTrip') {
          this.startTripSettings = this.startTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.pauseTripSettings = this.pauseTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.endTripSettings = this.endTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
        if (val === 'startTrip') {
          this.groupTripSettings = this.groupTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.pauseTripSettings = this.pauseTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.endTripSettings = this.endTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }

        if (val === 'pauseTrip') {
          this.groupTripSettings = this.groupTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.startTripSettings = this.startTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.endTripSettings = this.endTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }

        if (val === 'endTrip') {
          this.groupTripSettings = this.groupTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.startTripSettings = this.startTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.pauseTripSettings = this.pauseTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
      },
    },
  },
  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
    getEndTripSettings() {
      const allEndTripSettings = this.endTripSettings
      if (this.endTrip.complete_trip_after_failed_lock_attempts === false) {
        const removeSettingsKey = ['required_lock_attempts_to_complete_trip']
        const filteredSettings = allEndTripSettings.filter(
          (item) => !removeSettingsKey.includes(item.variable)
        )
        return filteredSettings
      } else {
        return this.endTripSettings
      }
    },
  },
  methods: {
    async onSave(apiGroup, key, val) {
      this.counter++
      let url = ''
      if (apiGroup === 'organization') {
        url = useEndpoints.organization.update(this.orgId)
      }
      if (apiGroup === 'vehicle') {
        url = VehicleSettingsConfig.api.index(this.orgId)
      }
      let toastTitle, toastText

      if (key === 'number_of_guests_allowed_per_trip') {
        toastTitle = `Number of guests allowed per trip`
        toastText = `Number of guests allowed per trip is updated`
      }
      if (key === 'min_power_level_for_trip') {
        toastTitle = `Trip Minimum Power`
        toastText = `Minimum power for starting trip is updated`
      }
      if (key === 'complete_trip_after_failed_lock_attempts') {
        toastTitle = `Complete trip after failed lock attempts`
        toastText = `Complete trip after failed lock attempts is updated`
      }
      if (key === 'required_lock_attempts_to_complete_trip') {
        toastTitle = `Required lock attempts to complete trip`
        toastText = `Required lock attempts to complete trip is updated`
      }

      if (key === 'pause_ride_hardware_action') {
        toastTitle = `Pause ride hardware action`
        toastText = `Pause ride hardware action is updated`
      }
      if (key === 'allow_unlock_outside_service_area') {
        toastTitle = `Outside of geofence`
        toastText = `Outside of geofence action is updated`
      }
      if (key === 'allow_unlock_inside_restricted_area') {
        toastTitle = `Inside restricted area`
        toastText = `Inside restricted area action is updated`
      }
      if (key === 'end_ride_location_verification') {
        toastTitle = `Ride Verification`
        toastText = `End ride location verification settings updated`
      }

      let data = new FormData()
      data.append(key, val)
      await this.$http
        .patch(url, data)
        .then((res) => {
          console.log(res)
          this.groupTripSettings = this.groupTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.startTripSettings = this.startTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.pauseTripSettings = this.pauseTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.endTripSettings = this.endTripSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: toastTitle,
              text: toastText,
            },
            3000
          )
          return true
        })
        .catch((err) => {
          console.log(err)
          this.$notify(
            {
              group: 'generic',
              type: 'danger',
              title: 'Failed',
              text: 'Failed to update settings',
            },
            3000
          )
        })
    },
  },
}
</script>
<style lang=""></style>
