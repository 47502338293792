<template>
  <section>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>
    <div class="max-w-6xl mx-auto mt-3 lg:mt-10 md:mt-5" v-if="isLoaded">
      <!-- one -->
      <div class="flex items-center justify-between">
        <div class="font-bold text-22px text-oCharcoal">
          <i class="fas fa-bicycle text-22px text-oPurple"></i>
          <span class="pl-3">Vehicle Settings </span>
        </div>
      </div>
      <div :key="`vehicle_${counter}`" class=" mt-3 pt-5 border-t-2">
        <template v-for="(item, itemIndex) in getVehicleSettings">
          <SmartAppInput
            :key="itemIndex"
            :rowId="`vehicle_${itemIndex}`"
            :type="item.type"
            :label="item.label"
            :description="item.description"
            :isEdit="item.isEdit"
            :options="item.options"
            v-model="vehicle[`${item.variable}`]"
            @save="onOff(`${item.variable}`, $event)"
          />
        </template>
      </div>

      <!-- battery -->
      <div class="flex justify-between mt-10">
        <div>
          <div class="flex items-center">
            <i class="fas fa-battery-three-quarters text-oOrange text-22px"></i>
            <div class="pl-3 font-bold text-22px text-oCharcoal">
              Battery Status
            </div>
          </div>
          <p class="break-words description-text ">
            Please provide ranges battery charge ranges which will be considered
            to the System as Critical, Low, Medium and High. Any vehicle battery
            status will be shown on the Map based on the ranges you will set
            here.
          </p>
        </div>
        <div
          class="col-span-12 pencil-icon md:col-span-3 justify-items-end justify-self-end"
        >
          <div v-if="!isBatteryEdit">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              @click="handleBatteryEdit"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="{2}"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              />
            </svg>
          </div>
        </div>
        <div v-if="isBatteryEdit" class="flex ml-3 mt-3 mb-4">
          <anchor-button
            @click="handleBatteryCancel"
            text="Cancel"
            width="6rem"
          />
          <anchor-button
            @click="onOff('battery_settings')"
            text="Save"
            variant="success"
            width="6rem"
            class="ml-4"
          />
        </div>
      </div>
      <div
        class="grid grid-cols-1 mt-6 gap-x-10 gap-y-4 md:grid-cols-2 lg:grid-cols-4  mt-3 pt-5  border-t-2"
      >
        <section>
          <input-label text="Critical" />
          <div class="flex items-center">
            <text-input class="pr-2" value="0" disabled />
            <div class="mb-6">-</div>
            <text-input
              type="number"
              v-model.number="battery.battery_critical_value"
              v-on:input="handleBatteryInputChange"
              :disabled="!isBatteryEdit"
              class="pl-2"
            />
          </div>
        </section>
        <section>
          <input-label text="Low" />
          <div class="flex items-center">
            <text-input
              :value="battery.battery_critical_value + 1"
              class="pr-2"
              disabled
            />
            <div class="mb-6">-</div>
            <text-input
              type="number"
              v-model.number="battery.battery_low_value"
              v-on:input="handleBatteryInputChange"
              :disabled="!isBatteryEdit"
              class="pl-2"
            />
          </div>
        </section>
        <section>
          <input-label text="Medium" />
          <div class="flex items-center">
            <text-input
              :value="battery.battery_low_value + 1"
              class="pr-2"
              disabled
            />
            <div class="mb-6">-</div>
            <text-input
              type="number"
              v-model.number="battery.battery_medium_value"
              v-on:input="handleBatteryInputChange"
              :disabled="!isBatteryEdit"
              class="pl-2"
            />
          </div>
        </section>
        <section>
          <input-label text="High" />
          <div class="flex items-center">
            <text-input
              :value="battery.battery_medium_value + 1"
              class="pr-2"
              disabled
            />
            <div class="mb-6">-</div>
            <text-input value="100" class="pl-2" disabled />
          </div>
        </section>
      </div>
      <!-- end: battery -->
    </div>
  </section>
</template>

<script>
import { VehicleSettingsConfig } from '@/config/SettingsConfig'
import inPlaceEditingMixin from '@/mixins/inPlaceEditingMixin'
import AnchorButton from '@/components/form/AnchorButton'
import InputLabel from '@/components/form/InputLabel'
import TextInput from '@/components/form/TextInput'
import SmartAppInput from '@/components/form/SmartAppInput.vue'
import { EventBus } from '@/utils/EventBus'
export default {
  name: 'VehicleSettings',
  mixins: [inPlaceEditingMixin],
  components: {
    InputLabel,
    TextInput,
    SmartAppInput,
    AnchorButton,
  },
  data() {
    return {
      // todo: add isLoaded for preventing pre-rendering until data is loaded
      isLoaded: false,
      counter: 0,
      settings: {},
      isBatteryEdit: false,
      vehicle: {
        vehicle_type: [],
        torch_on: false,
        torch_start_time: false,
        torch_end_time: false,
        auto_lock_vehicle_mins: null,
        heartbeat_idle_mins: null,
        battery_critical_value: null,
        battery_low_value: null,
        battery_medium_value: null,
        negative_balance_throttle_off: false,
      },

      battery: {
        battery_critical_value: 25,
        battery_low_value: 39,
        battery_medium_value: 59,
      },
      previousBatteryData: {
        battery_critical_value: null,
        battery_low_value: null,
        battery_medium_value: null,
      },

      vehicleSettings: [
        {
          type: 'richselect',
          label: 'Auto Lock Vehicle (Active Heartbeat)',
          description:
            'Define time interval for sending lock command by System Automatically for Lock those vehicles which are sending HEARTBEAT at regular interval , NOT ON TRIP and UNLOCKED somehow for various reasons(Network issue, hardware command respond delay issue etc). To Protect vehicles from theft it is useful.',
          options: [
            { value: 5, text: '5 m' },
            { value: 6, text: '6 m' },
            { value: 7, text: '7 m' },
            { value: 8, text: '8 m' },
            { value: 9, text: '9 m' },
            { value: 10, text: '10 m' },
          ],
          variable: 'auto_lock_vehicle_mins',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: 'Auto Lock Time (On No Heartbeat)',
          description: `Define time interval for sending lock command by System Automatically for Lock those vehicles which AREN'T sending HEARTBEAT(Network issue, hardware command respond delay issue etc) at regular interval , NOT ON TRIP and UNLOCKED. Keep in mind, while vehicle in inactive sometimes there is higher chance vehicle will not respond to Automatic System Lock Commands.`,
          options: [
            { value: 5, text: '5 m' },
            { value: 6, text: '6 m' },
            { value: 7, text: '7 m' },
            { value: 8, text: '8 m' },
            { value: 9, text: '9 m' },
            { value: 10, text: '10 m' },
          ],
          variable: 'heartbeat_idle_mins',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: 'Control throttle when rider balance is Negative',
          description:
            'While Rider is on trip and balance goes negative then System will Turn on/off based on following settings.',
          options: [
            { value: false, text: 'Throttle on' },
            { value: true, text: 'Throttle off' },
          ],
          variable: 'negative_balance_throttle_off',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: 'Headlight',
          description:
            'Select ON if you want vehicle headlight to be on at certain time.',
          options: [
            { value: 1, text: 'On' },
            { value: 0, text: 'Off' },
          ],
          variable: 'torch_on',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: 'Headlight Enabled Starting Hour',
          description:
            'Select the time which will be used to turn headlight ON when rider start the ride.',
          options: [
            { value: '00:00:00', text: '00:00 am' },
            { value: '01:00:00', text: '01:00 am' },
            { value: '02:00:00', text: '02:00 am' },
            { value: '03:00:00', text: '03:00 am' },
            { value: '04:00:00', text: '04:00 am' },
            { value: '05:00:00', text: '05:00 am' },
            { value: '06:00:00', text: '06:00 am' },
            { value: '07:00:00', text: '07:00 am' },
            { value: '08:00:00', text: '08:00 am' },
            { value: '09:00:00', text: '09:00 am' },
            { value: '10:00:00', text: '10:00 am' },
            { value: '11:00:00', text: '11:00 am' },
            { value: '12:00:00', text: '12:00 pm' },
            { value: '13:00:00', text: '13:00 pm' },
            { value: '14:00:00', text: '14:00 pm' },
            { value: '15:00:00', text: '15:00 pm' },
            { value: '16:00:00', text: '16:00 pm' },
            { value: '17:00:00', text: '17:00 pm' },
            { value: '18:00:00', text: '18:00 pm' },
            { value: '19:00:00', text: '19:00 pm' },
            { value: '20:00:00', text: '20:00 pm' },
            { value: '21:00:00', text: '21:00 pm' },
            { value: '22:00:00', text: '22:00 pm' },
            { value: '23:00:00', text: '23:00 pm' },
            { value: '24:00:00', text: '24:00 pm' },
          ],
          variable: 'torch_start_time',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: 'Headlight Enabled Ending Hour',
          description:
            'Select the time which will be used to turn headlight OFF when rider end the ride.',
          options: [
            { value: '00:00:00', text: '00:00 am' },
            { value: '01:00:00', text: '01:00 am' },
            { value: '02:00:00', text: '02:00 am' },
            { value: '03:00:00', text: '03:00 am' },
            { value: '04:00:00', text: '04:00 am' },
            { value: '05:00:00', text: '05:00 am' },
            { value: '06:00:00', text: '06:00 am' },
            { value: '07:00:00', text: '07:00 am' },
            { value: '08:00:00', text: '08:00 am' },
            { value: '09:00:00', text: '09:00 am' },
            { value: '10:00:00', text: '10:00 am' },
            { value: '11:00:00', text: '11:00 am' },
            { value: '12:00:00', text: '12:00 pm' },
            { value: '13:00:00', text: '13:00 pm' },
            { value: '14:00:00', text: '14:00 pm' },
            { value: '15:00:00', text: '15:00 pm' },
            { value: '16:00:00', text: '16:00 pm' },
            { value: '17:00:00', text: '17:00 pm' },
            { value: '18:00:00', text: '18:00 pm' },
            { value: '19:00:00', text: '19:00 pm' },
            { value: '20:00:00', text: '20:00 pm' },
            { value: '21:00:00', text: '21:00 pm' },
            { value: '22:00:00', text: '22:00 pm' },
            { value: '23:00:00', text: '23:00 pm' },
            { value: '24:00:00', text: '24:00 pm' },
          ],
          variable: 'torch_end_time',
          isEdit: false,
        },
      ],
    }
  },
  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
    getVehicleSettings() {
      // const allVehicleSettings = this.vehicleSettings
      // if (this.vehicle.complete_trip_after_failed_lock_attempts === false) {
      //   const removeSettingsKey = ['required_lock_attempts_to_complete_trip']
      //   const filteredSettings = allVehicleSettings.filter(
      //     (item) => !removeSettingsKey.includes(item.variable)
      //   )
      //   return filteredSettings
      // } else {
      //   return this.vehicleSettings
      // }
      return this.vehicleSettings
    },
  },

  async created() {
    this.$http
      .get(VehicleSettingsConfig.api.index(this.orgId))
      .then((res) => {
        console.log('settings', res.data)

        let data = res.data

        // delete data.torch_on;
        data.torch_on = res.data.torch_on === true ? 1 : 0

        this.vehicle = { ...data }

        this.$set(
          this.battery,
          'battery_critical_value',
          data.battery_critical_value
        )
        this.$set(this.battery, 'battery_low_value', data.battery_low_value)
        this.$set(
          this.battery,
          'battery_medium_value',
          data.battery_medium_value
        )

        this.isLoaded = true
      })
      .catch((err) => {
        console.log('settignsErr', err)
      })
  },
  mounted() {
    EventBus.$on('open-edit', (id) => {
      const idPrefix = id.split('_')[0]
      if (idPrefix === 'vehicle') {
        this.vehicleSettings = this.vehicleSettings.map((item, itemIndex) => {
          if (id === `vehicle_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        if (this.isBatteryEdit) {
          this.isBatteryEdit = false
          this.handleBatteryCancel()
        }
      }
    })
  },
  methods: {
    handleBatteryEdit() {
      this.vehicleSettings = this.vehicleSettings.map((item) => ({
        ...item,
        isEdit: false,
      }))
      this.isBatteryEdit = true
      this.previousBatteryData.battery_critical_value = this.battery.battery_critical_value
      this.previousBatteryData.battery_low_value = this.battery.battery_low_value
      this.previousBatteryData.battery_medium_value = this.battery.battery_medium_value
    },
    handleBatteryCancel() {
      this.isBatteryEdit = false
      this.battery.battery_critical_value = this.previousBatteryData.battery_critical_value
      this.battery.battery_low_value = this.previousBatteryData.battery_low_value
      this.battery.battery_medium_value = this.previousBatteryData.battery_medium_value
    },
    handleBatteryInputChange() {
      if (
        this.battery.battery_low_value < this.battery.battery_critical_value
      ) {
        this.battery.battery_low_value = this.battery.battery_critical_value + 1
      }
      if (this.battery.battery_medium_value < this.battery.battery_low_value) {
        this.battery.battery_medium_value = this.battery.battery_low_value + 1
      }
    },

    async onOff(key, val = null) {
      let toastTitle, toastText

      if (key === 'torch_on') {
        let onOffText = val === 1 ? 'on' : 'off'
        toastTitle = `Torch ${onOffText}`
        toastText = `Always on headlight turned ${onOffText}`

        if (val === 1) {
          val = true
        }
        if (val === 0) {
          val = false
        }
      }

      if (key === 'torch_start_time') {
        toastTitle = `Torch Schedule Updated`
        toastText = `Torch enabled starting time updated`
      }

      if (key === 'torch_end_time') {
        toastTitle = `Torch Schedule Updated`
        toastText = `Torch enabled ending time updated`
      }

      if (key === 'auto_lock_vehicle_mins') {
        toastTitle = `Auto Lock Time (Active Heartbeat)`
        toastText = `Automatic locking of vehicle time updated`
      }

      if (key === 'heartbeat_idle_mins') {
        toastTitle = `Auto Lock Time (On No Heartbeat)`
        toastText = `Automatic locking of vehicle time updated`
      }

      if (key === 'battery_settings') {
        toastTitle = `Battery Settings`
        toastText = `Battery Settings is updated`
      }

      let data = new FormData()
      if (key === 'battery_settings') {
        data.append(
          'battery_critical_value',
          this.battery.battery_critical_value
        )
        data.append('battery_low_value', this.battery.battery_low_value)
        data.append('battery_medium_value', this.battery.battery_medium_value)
      } else {
        data.append(key, val)
      }
      console.log('link', data)
      await this.$http
        .patch(VehicleSettingsConfig.api.update(this.orgId), data)
        .then((res) => {
          console.log(res)
          this.vehicleSettings = this.vehicleSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.isBatteryEdit = false
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: toastTitle,
              text: toastText,
            },
            3000
          )
          this.counter++
        })
        .catch((err) => {
          // todo: reverse toggle state
          console.log('onOffE', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: toastTitle,
              text: 'Failed to update settings',
            },
            3000
          )
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-for-torch {
  background-color: #f9fff8;
}
.size-for-torch {
  height: 58px;
}
.pencil-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.description-text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 2px;
  margin-bottom: 1px;
}
</style>
